<template>
  <div>
    <section class="two-column-section">
      <header class="two-column-section__header">
        <h2 class="h5">
          {{ $t('emails.sectionTitle') }}
        </h2>
        <p class="subtitle">
          {{ $t('emails.sectionSubtitle') }}
        </p>
      </header>

      <div class="two-column-section__content">
        <CyAlert
          theme="error"
          :content="errors"/>

        <ul class="list-style-none mb-4 pl-0 space-y-2">
          <v-card
            v-for="({ purpose, email, verified }, emailIndex) of profile.emails"
            :key="emailIndex"
            outlined
            class="d-flex align-center pa-4 space-x-4"
            tag="li"
            data-cy="email-list-item">
            <div class="flex-grow-1">
              <div class="space-x-1">
                <span class="font-weight-bold">{{ email }}</span>
                <CyTag
                  v-if="purpose === 'primary'"
                  data-cy="primary-email-tag"
                  variant="default">
                  {{ $t('emails.primaryEmailTagLabel') }}
                </CyTag>
                <CyTag
                  v-if="!verified"
                  data-cy="unverified-email-tag"
                  variant="warning">
                  {{ $t('emails.unverifiedEmailTagLabel') }}
                </CyTag>
              </div>
              <button
                v-if="!verified"
                data-cy="resend-verification-email-btn"
                class="cy-link mt-4"
                @click="resendVerificationEmail(email)">
                {{ $t('emails.resendVericiationEmailBtnText') }}
              </button>
            </div>
            <CyTooltip
              left
              :disabled="purpose !== 'primary'">
              <template #activator="{ on }">
                <div v-on="on">
                  <CyButton
                    data-cy="delete-email-btn"
                    aria-label="Delete Email button"
                    icon-only
                    variant="tertiary"
                    theme="primary"
                    icon="delete"
                    :disabled="purpose === 'primary'"
                    @click.native="deleteEmailAddress(email)"/>
                </div>
              </template>
              {{ $t('emails.primaryEmailDeleteTooltip') }}
            </CyTooltip>
          </v-card>
        </ul>

        <form
          class="d-flex space-x-4"
          @submit.prevent="addEmailAddress">
          <v-text-field
            ref="newEmailAddress"
            v-model="$v.newEmailAddress.$model"
            data-cy="add-email-address-field"
            class="input-medium required-field"
            :label="$t('emails.fieldAddEmailAddress')"
            :error-messages="newEmailErrors"
            @blur="$v.newEmailAddress.$reset"/>
          <CyButton
            data-cy="add-email-btn"
            name="add-email-btn"
            type="submit"
            class="mt-3"
            theme="secondary"
            icon="add"
            :loading="isAddingEmailAddress">
            {{ $t('emails.addEmailBtnText') }}
          </CyButton>
        </form>
      </div>
    </section>

    <v-divider class="my-8"/>

    <section class="two-column-section">
      <header class="two-column-section__header">
        <h2 class="h5">
          {{ $t('primaryEmail.sectionTitle') }}
        </h2>
        <p class="subtitle">
          {{ $t('primaryEmail.sectionSubtitle') }}
        </p>
      </header>

      <div class="two-column-section__content">
        <form
          class="d-flex space-x-4"
          @submit.prevent="setPrimaryEmailAddress">
          <v-select
            v-model="primaryEmailAddress"
            data-cy="primary-email-select"
            :label="$t('primaryEmail.fieldPrimaryEmail')"
            class="input-medium required-field"
            :items="verifiedEmails"
            item-text="email"
            item-value="email"/>
          <CyButton
            data-cy="set-primary-email-btn"
            name="set-primary-email-btn"
            type="submit"
            class="mt-3"
            theme="secondary"
            icon="done"
            :loading="isSettingPrimaryEmailAddress">
            {{ $t('forms.btnSave') }}
          </CyButton>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { constructBreadcrumb } from '@/utils/helpers'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageUserEmails',
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.userEmails'), [
      {
        label: this.$t('routes.profile'),
        name: 'profile',
      },
    ])
  },
  validations: {
    newEmailAddress: {
      required,
      email: (val) => email(val.toLowerCase()),
    },
  },
  data: () => ({
    profile: null,
    newEmailAddress: '',
    isAddingEmailAddress: false,
    primaryEmailAddress: null,
    isSettingPrimaryEmailAddress: false,
  }),
  computed: {
    ...mapState('user', {
      userProfile: (state) => state.profile,
      errors: (state) => state.errors.profile,
    }),
    newEmailErrors () {
      const errors = []
      const { $dirty, email, required } = this.$v.newEmailAddress
      if (!$dirty) return errors
      if (!email) errors.push(this.$t('forms.fieldNotEmail'))
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    verifiedEmails () {
      return _.filter(this.profile.emails, 'verified')
    },
  },
  created () {
    this.profile = _.cloneDeep(this.userProfile)
    this.primaryEmailAddress = _.find(this.userProfile.emails, { purpose: 'primary' })?.email
  },
  beforeDestroy () {
    this.CLEAR_USER_ERRORS()
  },
  methods: {
    ...mapMutations('user', [
      'CLEAR_USER_ERRORS',
    ]),
    ...mapActions('user', [
      'UPDATE_PROFILE',
      'EMAIL_VERIFICATION_RESEND',
    ]),
    async addEmailAddress () {
      this.$v.$touch()

      if (!this.$v.newEmailAddress.$invalid) {
        const { profile } = this
        this.isAddingEmailAddress = true
        profile.emails.push({
          email: this.newEmailAddress,
          purpose: 'additional',
        })
        await this.UPDATE_PROFILE({ profile })
        this.isAddingEmailAddress = false
        if (!this.errors.length) {
          this.newEmailAddress = ''
          this.$v.$reset()
        }
      }

      this.$refs.newEmailAddress.focus()
    },
    async deleteEmailAddress (email) {
      const { profile } = this
      const indexToDelete = _.findIndex(profile.emails, { email })
      if (indexToDelete !== -1) {
        profile.emails.splice(indexToDelete, 1)
        await this.UPDATE_PROFILE({ profile })
      }
    },
    async resendVerificationEmail (email) {
      await this.EMAIL_VERIFICATION_RESEND({ email })
    },
    async setPrimaryEmailAddress () {
      this.isSettingPrimaryEmailAddress = true
      const { profile } = this
      const indexToUpdate = _.findIndex(profile.emails, { email: this.primaryEmailAddress })

      if (indexToUpdate !== -1) {
        _.forEach(profile.emails, (obj, index) => {
          obj.purpose = (index === indexToUpdate) ? 'primary' : 'additional'
        })
        await this.UPDATE_PROFILE({ profile })
      }

      this.isSettingPrimaryEmailAddress = false
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.userEmails',
        emails: {
          sectionTitle: '@:forms.fieldEmails',
          sectionSubtitle: 'Manage email addresses for your account.',
          primaryEmailTagLabel: 'primary',
          unverifiedEmailTagLabel: 'unverified',
          resendVericiationEmailBtnText: 'Resend verification email',
          fieldAddEmailAddress: 'Add email address',
          addEmailBtnText: 'Add email',
          primaryEmailDeleteTooltip: 'Your primary email address cannot be deleted.',
        },
        primaryEmail: {
          sectionTitle: 'Primary email address',
          sectionSubtitle: 'Your primary email address is used for notifications and authentication related emails.',
          fieldPrimaryEmail: 'Primary email',
        },
      },
      es: {
        title: '@:routes.userEmails',
        emails: {
          sectionTitle: '@:forms.fieldEmails',
          sectionSubtitle: 'Administre las direcciones de correo electrónico de su cuenta.',
          primaryEmailTagLabel: 'primario',
          unverifiedEmailTagLabel: 'inconfirmado',
          resendVericiationEmailBtnText: 'Reenviar correo electrónico de verificación',
          fieldAddEmailAddress: 'Agregar dirección de correo electrónico',
          addEmailBtnText: 'Agregar correo electrónico',
          primaryEmailDeleteTooltip: 'Su dirección de correo electrónico principal no se puede eliminar.',
        },
        primaryEmail: {
          sectionTitle: 'Dirección de correo principal',
          sectionSubtitle: 'Su dirección de correo electrónico principal se utiliza para notificaciones y correos electrónicos relacionados con la autenticación.',
          fieldPrimaryEmail: 'Correo electrónico principal',
        },
      },
      fr: {
        title: '@:routes.userEmails',
        emails: {
          sectionTitle: '@:forms.fieldEmails',
          sectionSubtitle: 'Gérez les adresses e-mail de votre compte.',
          primaryEmailTagLabel: 'principal',
          unverifiedEmailTagLabel: 'non vérifié',
          resendVericiationEmailBtnText: `Renvoyer l'e-mail de vérification`,
          fieldAddEmailAddress: 'Ajouter une adresse e-mail',
          addEmailBtnText: 'Ajouter un e-mail',
          primaryEmailDeleteTooltip: 'Votre adresse e-mail principale ne peut pas être supprimée.',
        },
        primaryEmail: {
          sectionTitle: 'Adresse e-mail principale',
          sectionSubtitle: `Votre adresse e-mail principale est utilisée pour les notifications et les e-mails liés à l'authentification.`,
          fieldPrimaryEmail: 'E-mail principal',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.input-medium {
  max-width: 320px;
}
</style>
